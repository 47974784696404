// Font family
$primary-font: 'Open Sans', Arial, Helvetica, sans-serif;

$icon-font-1:'Linearicons-Free';
$icon-font-2:'FontAwesome';


// Fonts weight
$ultraLight: 100;
$light: 200;
$demi: 300;
$normal: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;
$black: 800;
$ultraBlack: 900;
$italic: italic;

// Animation
$slow: all 0.5s;
$fast: all 0.3s;

// Color
$primary-color: #0177d7;
$accent-color: #f39c12;

$black: #000000;
$white:#fff;

$white:#fff;
$white-grey:#e5e5e5;
$lightest-grey:#f5f5f5;
$grey-1:#808080;
$grey-2:#666666;
$grey-3:#888888;
$dark-grey:#555555;
$darkest-grey:#333333;
$grey-4: #999999;
$grey-6: #f2f2f2;
$grey-5: #ccc;

$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #bb0000;
$vimeo: #aad450;
$tumblr: #32506d;
$instagram: #517fa4;
$flickr: #ff0084;
$dribbble: #ea4c89;
$quora: #a82400;
$foursquare: #0072b1;
$forrst: #5B9A68;
$vk: #45668e;
$wordpress: #21759b;
$stumbleupon: #EB4823;
$yahoo: #7B0099;
$blogger: #fb8f3d;
$soundcloud: #ff3a00;