/**
ABOUT PAGE
 */

.about-page {
  .testinmonials {
    margin-top: 50px;
  }
  @include respond-to("md-tablet") {
    .content-box-item-style-2 {
      margin-bottom: 50px;
    }
  }
}