/**
icon box layout
 */
.icon-box-layout {


  &.style-1 {
    background: #f0f5f8;
    padding: 85px 0;
    .icon-box-item {
      margin-bottom: 30px;
    }
    .heading-layout {
      text-align: center;
      h3 {

      }
    }
    .sub-heading {
      padding-bottom: 50px;
      text-align: center;
      span {
        font-size: 16px;
        color: #888888;
        font-weight: $semiBold;
      }
    }

    @include respond-to("md-tablet") {
      .heading-layout, .sub-heading {
        text-align: left;
      }
    }
  }


}

.icon-box-item {
  .icon-box-icon {
    width: 50px;
    height: 50px;
    display: table;
    text-align: center;
    background: $primary-color;
    float: left;
    i {
      display: table-cell;
      vertical-align: middle;
      font-size: 26px;
      color: #f0f5f8;
    }
  }
  .icon-box-main {
    margin-left: 50px;
    padding-left: 20px;
  }
  .icon-box-title {
    line-height: 1;
    padding-bottom: 10px;
    a {
      font-size: 14px;
      font-weight: $bold;
      color: #555555;
      &:hover {
        color: $accent-color;
      }
    }
  }
  .icon-box-content {
    p {
      font-size: 14px;
      line-height: (22/14);
    }
  }
}