/**
BASE STYLE
 */
body {
  font: 14px/1.6 $primary-font;
  color: #666666;
  overflow-x: hidden !important;
}

.heading-section {
  font-size: 36px;
  color: #0177d7;
}

.bold {
  font-weight: $bold;
}

/**
  a, button
 */

*:focus {
  outline: none;
}

a {
  text-decoration: none !important;
}

a, button {
  transition: $fast !important;
}

@include respond-to('desktop-up'){
  .container {
    width: 1200px;
  }
}

.vimeo {
  margin: 0;
  position: relative;
  padding-bottom: 71.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
  a {
    display: block;
    position: relative;
    img {
      max-width: 100%;
    }
  }
  .btn-play {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -32px 0 0 -32px;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}