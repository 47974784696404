
/**
Contact Layout
 */
.contact-layout {


  &.style-1 {
    margin-bottom: 10px;
    position: relative;
    .heading {
      h4 {
        font-weight: $bold;
        color: $white;
        font-size: 18px;
        margin-top: 5px;
        margin-bottom: 0;
      }
    }
    .google-map {
      height: 670px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }

    .contact-block {
      width: 900px;
      box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
      margin: 90px auto 0;
    }

    .contact-form {
      float: left;
      background: $primary-color;
      padding: 35px 35px 30px;
      height: 100%;
      .contact-form-item {
        padding-bottom: 5px;
      }
      .head-contact-form {
        text-align: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding-bottom: 35px;
        .fa {
          color: $white;
          font-size: 36px;
          margin-bottom: 5px;
        }
      }

      .contact-form-body {
        .input-title {
          display: block;
          color: $white;
          font-size: 14px;
          font-weight: $bold;
          margin: 10px 0 7px;
        }
        input, textarea {
          width: 100%;
          color: #888888;
          padding: 13px 20px;
          font-size: 14px;
        }
        textarea {
          min-height: 130px;
          resize: vertical;
        }
        button {
          margin-top: 10px;
        }
      }
    }

    .contact-info {
      float: right;
      padding: 35px;
      background: $white;
      height: 100%;
      .head-contact-info, .head-time-open {
        .heading {
          h4 {
            color: #333333;
          }
        }
        text-align: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding-bottom: 35px;
        .fa {
          color: #333333;
          font-size: 36px;
          margin-bottom: 5px;
        }
      }
      .head-time-open, .head-contact-info {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
      ul {
        padding: 0;
      }

      .list-contact-info {
        margin-top: 15px;
        list-style:none;
        padding-bottom: 15px;
        li {
          font-size: 14px;
          color: #666666;
          margin-bottom: 10px;
          .bold {
            font-weight: $bold;
          }
        }
      }
      .list-time-open {
        display: block;
        margin-top: 15px;
        li {
          display: block;
          margin-bottom: 10px;
          font-size: 14px;
          .day {
            display: inline-block;
          }
          .time {
            float: right;
            text-align: left;
            display: inline-block;
            width: 153px;
          }
          &:after {
            clear: both;
            content: ".";
            display: block;
            height: 0;
            line-height: 0;
            visibility: hidden;
          }
        }
      }
    }

    @include respond-to("md-tablet") {
      height: auto;
      .contact-block {
        width: auto;
      }
      .contact-info {
        margin-top: 15px;
      }
    }
  }


  &.style-2 {
    background-size: cover !important;
    padding-bottom: 60px;
    margin-bottom: 0;
    .contact-block {
      box-shadow: none;
      width: auto;
      padding: 0 365px;
      margin-top: 40px;
      .contact-form {
        width:100%;
        background: transparent;
        .head-contact-form {
          border: none;
          .heading {
            h3 {
              font-size: 36px;
              font-weight: $normal;
              color: $white;
              margin: 0;
            }
          }
        }
        .btn-area {
          text-align: center;
        }
      }
    }

    @include respond-to("lg-tablet") {
      .contact-block {
        padding: 0 250px;
      }
    }
    @include respond-to("md-tablet") {
      .contact-block {
        padding: 0 100px;
      }
    }
    @include respond-to("md-phone") {
      .contact-block {
        padding: 0;
      }
    }
  }


  &.style-3 {
    .main-contact {
      padding: 65px 0 100px 0;
    }
    .heading {
      margin-bottom: 35px;
      h3 {
        font-size: 20px;
        color: #333333;
        font-weight:$bold;
      }
    }
    /*Conatct Form*/
    .contact-form {

      .input-group {
        width: 100%;
        margin-bottom: 20px;
        .input {
          padding-right: 20px;
          width: 33.33%;
          float: left;
          &:last-child {
            padding-right:  0;
          }
        }
        input {
          width: 100%;
          border-radius: 3px;
          padding: 13px 20px;
          border: 1px solid #ccc;
        }
      }

      .text-area {
        margin-bottom: 10px;
        textarea {
          width:100%;
          border: 1px solid #ccc;
          border-radius: 3px;
          min-height: 150px;
          padding: 13px 20px;
        }
      }

    }

    /*Contact Info*/
    .contact-info {
      padding-left: 20px;
      .subtitle {
        font-size: 14px;
        color: #666;
        margin-bottom: 20px;
      }

      .contact-list {
        padding: 0;
        list-style: none;
        li {
          line-height: 2.5;
          font-size: 14px;
          i {
            margin-right: 7px;
          }
        }
      }
    }
    /*Map*/
    .google-map {
      height: 400px;
      width: 100%;
      position: relative;
      left: 0;
      top: 0;
      z-index: 1;
    }

    @include respond-to("lg-tablet") {
      .contact-info {
        padding-left: 0;
      }
      .contact-form {
        margin-bottom: 50px;
      }
    }
    @include respond-to("sm-phone") {
      .contact-form .input-group .input {
        width: 100%;
        padding-right: 0;
        margin-bottom: 15px;
        input {
          width: 100%;
        }
      }
    }
  }


  &.style-4 {
    padding: 70px 0 50px 0;
    .heading {
      margin-bottom: 15px;
      h3 {
        font-size: 24px;
        color: #222222;
        font-weight: $bold;
      }
    }

    .main-post {
      .content {
        p {
          font-size: 14px;
          color: #666;
          line-height: (25/14);
          margin-bottom: 25px;
        }
      }
    }

    .main-contact {
      .contact-list {
        p {
          font-size:  13px;
          i {
            margin-right: 5px;
            font-size: 16px;
            color: $accent-color;
            width:20px;
          }
          .bold {
            text-transform: uppercase;
            font-weight: $bold;
            margin-right: 5px;
          }
        }
        .time-list {
          padding-left: 30px;
          list-style: none;
          li {
            font-size: 14px;
            line-height: (24/14);
            @include clearfix;
            .text-left {
              float: left;
            }
            .text-right {
              text-align: left;
              width:50%;
              float: right;
            }
          }
        }
      }
    }
  }


}