/**
  ALL PRODUCT LAYOUT
 */

.product {

}

/*ITEM STYLE PRIMARY*/
.product-item {

  &.style-1 {
    margin-bottom: 40px;
    /*image*/
    .image {
      img {
        width: 100%;
      }
    }

    /*title*/
    .title {
      margin: 15px 0 10px 0;
      a {
        &:hover {
          color: $accent-color;
        }
        font-size: 18px;
        font-weight: $bold;
        text-transform: uppercase;
        color: #0177d7;
      }
    }

    /*Content*/
    .content {
      p {
        font-size: 14px;
        color: #666;
      }
    }
  }


  &.style-2 {
    text-align: center;
    padding: 15px;
    background: $white;
    margin-bottom: 20px;
    border-radius: 3px;
    transition: $slow;
    &:hover {
      background: $primary-color;
      transition: $slow;
      .icon, .title, .content, .view-more {
        i, a, p {
          color: $white;
          transition: $slow;
        }
      }
      .view-more a {
        text-decoration: underline !important;
      }
    }
    .icon {
      padding-bottom: 10px;
      padding-top: 15px;
      i {
        font-size: 36px;
        color: #0177d7;
      }
    }

    .title {
      padding-bottom: 10px;
      a {
        font-weight: $bold;
        font-size: 18px;
        color: #555555;
        text-transform: uppercase;
      }
    }

    .content {
      padding-bottom: 10px;
      p {
        font-size: 14px;
        line-height: (22/14);
      }
    }

    .view-more {
      padding-bottom: 15px;
      a {
        font-size: 14px;
        color: #0177d7;
      }
    }
  }


}


/*AT HOME 1*/
.product-layout {


  &.style-1 {
    /*Heading*/
    .heading {
      margin-bottom: 30px;
      h3 {
        font-size: 36px;
        color: #0177d7;
      }
    }
  }


  &.product-layout-none-heading {
    padding: 75px 0 65px 0;
  }

  /*ANGET DETAIL*/
  &.style-2 {
    /*Heading*/
    .heading {
      h3 {
        font-size: 24px;
        color: #222222;
        font-weight: $bold;
        margin-bottom: 5px;
      }
    }
    .lead {
      margin-bottom: 25px;
      span {
        font-size: 15px;
        color: #999999;
        font-style: italic;
        font-weight: $medium;
      }
    }
  }

  &.style-3 {
    background: #f0f5f8;
    padding: 60px 0 90px 0;
    .heading {
      text-align: center;
      padding-bottom: 15px;
    }
    .sub-heading {
      text-align: center;
      padding-bottom: 35px;
      span {
        font-size: 18px;
        color: #888888;
        line-height: (24/18);
      }
    }
  }


}

