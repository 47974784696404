/**
Footer
 */
.main-footer {
  background-color: #2e363a;
  padding: 40px 0;

  .services-widget {

    .logo {
      margin-bottom: 34px;
    }
    .services-list {
      border-right: 1px solid rgb(56, 66, 71);
      margin-right: 30px;
      padding-bottom: 30px;
      ul {
        margin: 0;
        padding: 0;
        li {
          list-style: square inside;
          line-height: 2;
          @include clearfix;
          a {
            font-size: 13px;
            color: rgb(204, 204, 204);
            display: inline-block;
          }
          &:hover {
            color: $white;
            transition: $fast;
            a {
              color: $white;
            }
          }
        }
      }
    }
  }

  .contact-widget {

    h3 {
      margin-bottom: 30px;
      font-size: 24px;
      color: #999999;
    }
    .content {
      padding-right: 50px;
      padding-bottom: 22px;
      margin-right: 20px;
      border-right: 1px solid rgb(56, 66, 71);
      p {
        font-size: 13px;
        color: #cccccc;
        margin: 0;
        line-height: 2;
        .bold {
          font-weight: $bold;
          margin-right: 5px;
        }
      }
    }
  }

  .social-widget {

    h3 {
      margin-bottom: 34px;
      font-size: 24px;
      color: #999999;
    }
    .social-list {
      padding: 0;
      li {
        float: left;
        border-radius: 3px;
        list-style: none;
        width:36px;
        height: 36px;
        display: table;
        text-align: center;
        background: #434a4e;
        margin-right: 5px;
        transition: $fast;
        a {
          display: table-cell;
          vertical-align: middle;
          color: $white;
        }
        &:hover {
          background: $primary-color;
          transition: $fast;
        }
      }
    }
  }

  @include respond-to('md-tablet') {
    .services-widget .services-list, .contact-widget .content {
      border: none;
    }
  }
}

.sub-footer {
  padding: 35px 0;
  background-color: #282f33;
  .copyright {
    float: left;
    font-size: 12px;
    color: #888888;
    margin: 0;
    line-height: 1;
  }
  .quick-link {
    float: right;
    padding: 0;
    line-height: 1;
    margin: 0;
    margin: 0 -15px;
    li {
      list-style: none;
      display: inline-block;
      padding: 0 15px;
      a {
        font-size: 12px;
        color: #888888;
        &:hover {
          color: $accent-color;
        }
      }
    }
  }

  @include respond-to('md-tablet') {
    .copyright {
      display: block;
      margin-bottom: 15px;
    }
    .quick-link {
      float: left;
    }
  }
}