/**
GET A QUOTE PAGE
 */

.get-a-quote-1 {
  padding: 30px 0 100px 0;
  background: #f0f5f8;
  .content-box-layout {
    margin-top: 110px;
  }
  .content-box-item {
    margin-bottom: 30px;
  }
}

.get-a-quote-2 {
  .content-box-item-style-1 {
    width: 100%;
  }
  .quote-container {
    padding: 30px 0 85px 0;
    background: #0177d7;
  }
  .content-box-container {
    background-size: cover !important;
  }
  .content-box {
    padding: 100px 0;
  }

  @include respond-to("lg-tablet") {
    .content-box-item-style-1 {
      margin-bottom: 30px;
    }
  }
}

.get-a-quote-3 {
  padding-bottom: 100px;
  .quote-container {
    padding-bottom: 60px;
  }
  @include respond-to("md-to-lg-tablet") {
    .quote-form-style-3 .quote-form-container .quote-form-heading {
      padding-bottom: 10px;
    }
    .heading-get-a-quote-2 .heading-content {
      position: absolute;
      top: 10px;
      left: 25px;
      padding-right: 15px;
    }
    .content-box-item-style-1 {
      margin-bottom: 30px;
      width: 100%;
    }
    .quote-form-style-3 .quote-form-button {
      padding-top: 10px;
    }
  }

  @include respond-to("lg-tablet") {
    .heading-get-a-quote-2 .heading-content {
      padding-right: 15px;
    }
    .heading-image {
      margin-bottom: 30px;
    }
    .content-box-item-style-1 {
      margin-bottom: 30px;
      width: 100%;
    }
  }
  @include respond-to("sm-phone") {
    .quote-form-style-3 .quote-form-container {
      padding: 15px 20px 40px 20px;
    }
  }
}