/**
BLOG PAGE
 */

.blog-list {
  .blog-list-wrapper {
    margin-right: -15px !important;
    border-right: 1px solid #ebebeb;
    padding: 70px 50px 70px 0;
  }
  .sidebar {
    position: relative;
    padding-left: 20px;
    padding-top: 50px;
  }
  .sidebar-banner {
    margin-left: -35px;
  }

  @include respond-to("lg-tablet") {
    .blog-item .blog-image {
      width: 100%;
      padding-bottom: 15px;
    }
    .blog-item .blog-main {
      padding-left: 0;
      width: 100%;
    }
  }
  @include respond-to("md-tablet") {
    .blog-list-wrapper {
      margin-right: 0 !important;
      border-right: 0;
      padding: 70px 0 70px 0;
    }
    .sidebar {
      padding-top: 0;
      padding-bottom: 50px;
      padding-left: 0;
      .sidebar-element {
        padding-left: 0;
      }

      .sidebar-banner {
        padding-left: 0;
        margin-left: 0;
      }
    }
  }
  @include respond-to("sm-phone") {
    .pagination-list li {
      margin-bottom: 15px;
    }
  }
}

.blog-single {
  .sidebar {
    padding-top: 50px;
    .sidebar-element {
      padding-left: 20px;
    }
    .sidebar-banner {
      padding-left: 0;
      margin-left: -15px;
    }
  }
  .blog-single {
    padding: 45px 50px 100px 0;
    margin-right: -15px !important;
    border-right: 1px solid #ebebeb;
    .post-title {
      padding-bottom: 15px;
    }

    .post-info {
      padding-bottom: 30px;
    }

    .post-image {
      padding-bottom: 5px;
    }
    .post-paragraph {
      padding-bottom: 10px;
    }
    .post-lists {
      padding-bottom: 30px;
    }
    .post-footer {
      margin-bottom: 35px;
    }
    .post-more-link {
      padding-bottom: 30px;
    }
    .post-comments {
      .post-heading {
        padding-bottom:5px;
      }
      .post-content {
        padding-bottom: 15px;
      }
    }

    .post-comments-form {
      padding-bottom:45px;
    }

    .post-comments-list {
      .post-heading {
        padding-bottom:25px;
      }
    }

  }

  @include respond-to("md-tablet") {
    .blog-single {
      padding: 45px 0 50px 0;
      margin-right: 0 !important;
      border-right: 0;
      .post-more-link .post-prev {
        margin-bottom: 15px;
      }
      .post-more-link .post-next {
        float: left;
      }
    }
    .sidebar {
      padding-top: 0;
      padding-bottom: 50px;
      .sidebar-element {
        padding-left: 0;
      }

      .sidebar-banner {
        padding-left: 0;
        margin-left: 0;
      }
    }
  }
}