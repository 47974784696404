/**
Sidebar Layout
 */

.sidebar {

}

.sidebar-style-1 {
  .sidebar-container {
    list-style: none;
    padding: 0;
    .sidebar-item {
      border-bottom: 1px solid #3492df;
      a {
        display: block;
        background: $primary-color;
        padding: 10px 0 10px 13px;
        border-left: 5px solid #025aa3;
        font-size: 14px;
        font-weight: $bold;
        text-transform: uppercase;
        color: $white;
        i {
          font-size: 16px;
          margin-right: 15px;
        }
        &:hover {
          background: #f39c12;
          border-left: 5px solid #bf7b0d;
        }
      }
      &:hover {
        border-bottom: 1px solid #f5b041;
      }
      &.active {
        border-bottom: 1px solid #f5b041;
        a {
          background: #f39c12;
          border-left: 5px solid #bf7b0d;
        }
      }
    }
  }
  
  .down-file-widget {
    padding: 25px;
    background: #f2f2f2;
    border: 1px solid #d9d9d9;
    .widget-title {
      padding-bottom: 15px;
      h4 {
        font-size: 14px;
      }
    }
    .widget-button {
      a {
        display: block;
        width:100%;
        padding: 10px 30px;
        background-color: $primary-color;
        color: $white;
        font-weight: normal;
        text-transform: none;
        margin-bottom: 10px;
      }
    }
  }
}

.sidebar-style-2 {
  .sidebar-container .sidebar-item {
    border-bottom: 1px solid #e6e6e6;
    &:hover {
      border-bottom: 1px solid #e6e6e6;
    }
    a {
      background: #f2f2f2;
      border-left: 5px solid #d9d9d9;
      color: $primary-color;
      text-transform: none;
      &:hover {
        background: #f2f2f2;
        color: $accent-color;
        border-left: 5px solid $accent-color;
      }
    }
    &.active {
      a {
        background: #f2f2f2;
        border-left: 5px solid $accent-color;
        color: $accent-color;
      }
      border-bottom: 1px solid #e6e6e6;
    }
  }

  .contact-widget {
    padding: 30px 35px;
    border: 1px solid #d9d9d9;
    .heading-widget {
      padding-bottom: 20px;
      h3 {
        font-size: 24px;
        color: #333333;
      }
    }

    .contact-list {
      list-style: none;
      padding:0;
      margin: 0;
      li {
        line-height: 1.5;
        font-size: 14px;
        padding-bottom: 10px;
        i {
          float: left;
          line-height: 1.5;
        }
        span {
          margin-left: 25px;
          display: block;
        }
      }
    }
  }
}


.sidebar-style-3 {
  .sidebar-element {
    padding-bottom:30px;
  }
  .sidebar-heading {
    padding-bottom:15px;
    h3 {
      font-weight: $bold;
      color: #333333;
      font-size: 18px;
    }
  }

  .sidebar-archive-list, .sidebar-cate-list {
    ul {
      list-style:none;
      padding: 0;
      margin: 0;
      li {
        line-height: 2;
        padding-bottom: 5px;
        a, span {
          &:hover {
            color: $accent-color;
          }
          i {
            margin-right: 7px;
          }
          font-size: 14px;
          color: #555555;
        }
      }
    }
  }

  .sidebar-archive-list {
    li {
      @include clearfix;
    }
    a {
      float: left;
    }
    .post-total {
      float: right;
      color: #999999;
      &:before {
        content: '(';
      }
      &:after {
        content: ')';
      }
    }
  }
}