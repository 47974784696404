/**
Project List
 */
.project-subjects {
  ul {
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      line-height: 1;
      padding-right: 40px;
      cursor: pointer;
      h4 {
        margin: 0;
        font-size: 15px;
        color: #555555;
      }
      &.is-checked {
        h4 {
          color: $primary-color;
        }
      }
      &:hover {
        h4 {
          transition: $fast;
          color: $primary-color;
        }
      }
    }
  }
  @include respond-to("md-phone") {
    ul {
      li {
        display: block;
        margin-bottom: 10px;
      }
    }
  }
}

.project-item {
  margin-bottom: 35px;
  .project-item-img {
    padding-bottom: 5px;
  }
  .project-item-title {
    padding-bottom: 2px;
    a {
      font-size: 18px;
      color: #333333;
      line-height: 2;
      font-weight: $bold;
      &:hover {
        color: $accent-color;
      }
    }
  }
  .project-item-subjects {
    font-size: 15px;
    color: #9f9f9f;
    line-height: 1;
  }
}

.projects-layout {
  .project-subjects {
    padding-bottom: 50px;
  }
}