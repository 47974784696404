/**
Breadcrumb
 */
.au-breadcrumb {
  list-style: none;
  padding: 0;
  margin: 0;
  @include clearfix;
  .au-breadcrumb-item {
    float: left;
    &.active {
      a {
        pointer-events: none;
        cursor: default;
      }
    }
    a {
      &:hover {
        color: $primary-color;
      }
      font-size: 14px;
      color: #555555;
    }
    &:after {
      font-family: $icon-font-2;
      content: '\f105';
      margin: 0 5px;
    }
    &:last-child {
      &:after {
        content: '';
        display: none;
      }
    }
  }
}