/**
Blog Layout
 */
.blog-item {
  @include clearfix;
  margin-bottom: 50px;
  .blog-image {
    width: 44.44%;
    float: left;
  }
  .blog-main {
    padding-left: 30px;
    float: left;
    width: (100 - 44.44%);
  }

  .blog-title {
    padding-bottom: 5px;
    a {
      font-size: 18px;
      font-weight: $bold;
      color: #333333;
      &:hover {
        color: $accent-color;
      }
    }
  }

  .blog-topic {
    display: inline-block;
    padding-bottom: 10px;
    margin-right: 30px;
    a {
      &:hover {
        color: $accent-color;
      }
      &:after {
        content: ',';
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      font-size: 11px;
      color: #0177d7;
      text-transform: uppercase;
    }
  }
  .blog-date {
    display: inline-block;
    span {
      text-transform: uppercase;
      font-size: 11px;
      color: #999999;
    }
  }

  .blog-content {
    padding-bottom: 5px;
    p {
      font-size: 14px;
      line-height: (25/14);
    }
  }

  .blog-link {
    a {
      &:hover {
        color: $accent-color;
      }
      font-size: 15px;
      color: #333333;
    }
  }

  /*End Blog Item*/
}

.blog-single {
  .post-title {
    h1 {
      font-size: 30px;
      color: #333333;
      font-weight: $bold;
    }
  }

  .post-info {
    font-size: 11px;
    color: #999999;
    text-transform: uppercase;
    .post-topic {
      margin-right: 35px;
    }
    .post-topic, .post-date {
      display: inline-block;
      a {
        color: $primary-color;
      }
    }
  }

  .post-image {
    img {
      width: 100%;
    }
  }

  .post-quote {
    padding: 15px 0 15px 30px;
    blockquote {
      font-size: 14px;
      color: #333333;
      border-left: 2px solid #cccccc;
    }
    .quote-author {
      padding-top: 15px;
      font-weight: $semiBold;
      display: block;
      text-transform: uppercase;
    }
  }

  .post-lists {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        line-height: 2.2;
        font-size: 14px;
        i {
          margin-right: 7px;
          color: $primary-color;
        }
      }
    }
  }

  .post-more-link {
    @include clearfix;
    .link {
      padding-bottom: 5px;
      a {
        font-size: 11px;
        color: #999999;
        text-transform: uppercase;
        font-weight: $bold;
      }
    }
    .post-prev {
      float: left;
      i {
        margin-right: 3px;
      }
    }
    .post-next {
      float: right;
    }
    .title {
      h4 {
        margin: 0;
        font-weight: $bold;
        font-size: 15px;
        color: #333333;
      }
    }
  }

  .post-footer {
    @include clearfix;
    padding-bottom: 15px;
    border-bottom: 1px solid #cccccc;
    a, span {
      text-transform: uppercase;
      font-size: 11px;
      color: $primary-color;

    }
    a:hover {
      color: $accent-color;
    }
    span {
      color: #999999;
    }
    .post-share {
      float: right;
    }
    .post-tags {
      float: left;
    }
  }

  .post-comments {
    .post-comments-form {
      .post-comments-form-group {
        padding-bottom: 20px;
        width:100%;
        @include clearfix;
        .post-comments-form-item {
          &:first-child {
            padding-right: 10px;
          }
          &:last-child {
            padding-left: 10px;
          }
          width:50%;
          float: left;
          input {
            width:100%;
          }
        }
      }
      .post-comments-form-textarea {
        padding-bottom: 15px;
        textarea {
          max-width: 100%;
          width: 100%;
          min-height: 150px;
        }
      }
    }
    &-submit {
      button {
        background: #555555;
        color: $white;
        font-weight: $normal;
        text-transform: none;
        font-size: 18px;
        padding: 10px 30px;
        &:hover {
          background: #333333;
        }
      }
    }
  }

  .post-comments-list {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .post-comments-has-reply {
      .post-comments-item {
        border-bottom:none;
      }
      .post-reply-lists {
        margin-left: 70px;
        border-bottom: 1px solid #e6e6e6;
        margin-bottom: 30px;
        .post-comments-item {
          padding-bottom: 40px;
        }
      }
    }

    .post-comments-item {
      padding-bottom: 30px;
      border-bottom: 1px solid #e6e6e6;
      .post-comments-item-image {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        overflow: hidden;
        float: left;
      }
      .post-comments-item-main {
        margin-left: 50px;
        padding-left: 15px;
        .content {
          font-size: 14px;
          line-height: (24/14);
        }
        .sub-content {
          font-size: 11px;
          text-transform: uppercase;
          span {
            color: #999999;
            margin-right: 20px;
          }
          .btn-reply {
            visibility: hidden;
            opacity: 0;
            cursor: pointer;
            font-weight: $semiBold;
            i {
              margin-right: 7px;
            }
            color: $primary-color;
          }
        }
      }
      &:hover {
        .post-comments-item-main .sub-content .btn-reply {
          visibility: visible;
          transition: $slow;
          opacity: 1;
        }
      }
    }
  }


}