/**
Project detail
 */
.projects-detail {
  @include clearfix;
  .projects-detail-heading {
    padding-bottom: 15px;
    h3 {
      margin-top: 0;
      font-size: 18px;
      color: #333333;
      font-weight: $bold;
    }
  }
  .projects-detail-content {
    p {
      font-size: 14px;
    }
  }

  .projects-detail-detail {
    padding-bottom:15px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    ul li {
      font-size: 14px;
      color: #666666;
      margin-bottom: 15px;
    }
  }

  .projects-detail-plan {
    p {
      color: #666666;
      margin-bottom: 30px;
    }
  }

  .project-detail-imageMain {
    img {
      width: 100%;
    }
    margin-bottom: 30px;
  }

  .project-detail-thumb {
    margin: 0 -15px;
    .thumb-item {
      width: 50%;
      float: left;
      padding: 0 15px;
      img {
        width: 100%;
        border-radius: 3px;
      }
    }
  }
}