/**
PROJECT PAGE
 */
.project-list-page {
  .projects-layout {
    padding: 60px 0 100px 0;
  }
  .projects-layout .project-subjects {
    padding-bottom: 45px;
  }
}

.projects-detail-page {
  .projects-detail {
    padding: 70px 0;
  }
}