/**
Content box layout
 */

.content-box-item {


  &.style-1 {
    .content-box-icon {
      width: 50px;
      height: 50px;
      display: table;
      background: $primary-color;
      border-radius: 50px;
      text-align: center;
      float: left;
      i {
        font-size: 26px;
        display: table-cell;
        vertical-align: middle;
        color: $white;
      }
    }
    .content-box-main {
      margin-left: 50px;
      padding-left: 15px;
      .title {
        h4 {
          font-size: 18px;
          color: #555555;
          font-weight: $bold;
        }
      }
      .content {
        p {
          font-size: 14px;
          line-height: (22/14);
        }
      }
    }
  }


  &.style-2 {
    text-align: center;
    border: 1px solid #cacacc;
    display: table;
    padding: 35px;
    .content-holder {
      vertical-align: middle;
      display: table-cell;
    }
    .content-box-icon {
      margin: 0 auto;
      background: none;
      float: none;
      text-align: center;
      padding-bottom: 10px;
      i {
        font-size: 48px;
        color: #333333;
      }
    }
    .content-box-main {
      margin-left: 0;
      .title {
        padding-bottom: 10px;
        h4 {
          font-size: 24px;
          color: #333333;
        }
      }
      .content {
        p {
          font-size: 14px;
          color: #666666;
        }
      }
    }
  }


  /**
 ABOUT PAGE
 */
  &.style-3 {
    text-align: center;
    .icon-present {
      margin-bottom: 30px;
      i {
        font-size: 48px;
        color: #0177d7;
      }
    }
    .title {
      margin-bottom: 14px;
      h4 {
        font-size: 18px;
        font-weight: $bold;
        color: #0177d7;;
      }
    }

    .subtitle {
      p {
        font-size: 14px;
        color: #666;
      }
    }
  }


}

.content-box-layout {
  &.style-3 {
    padding: 80px 0;
    background-color: #f0f5f8;
  }
}