/**
Control Spacing Layout at Home Page 1
 */

.home-page-1 {

  .product-layout {
    padding: 55px 0 70px 0;
  }

  .testinmonials-layout {
    padding: 50px 0 90px 0;
  }

}

/**
HOME PAGE 2
 */

.home-page-2 {
  .team-layout.style-1 {
    padding: 60px 0 70px 0;
  }
  .partner-layout.style-1 {
    padding: 80px 0 60px 0;
  }
}