/**
  ALL CALL TO ACTION LAYOUT
 */

// HOME 1
.call-to-action-layout {
  &.style-1 {
    width: 100%;
    background-size: cover !important;
    padding: 200px 0 175px 0;
    .call-to-action-content {
      h4 {
        font-size: 48px;
        color: $white;
        line-height: 57px;
        margin-bottom: 35px;
      }
    }

    .call-to-action-form {
      .call-to-action-form-input {
        padding-right:20px;
        display: inline-block;
        min-width: 310px;
        position: relative;
        &:before {
          position: absolute;
          left: 18px;
          top: 13px;
          content: '\f041';
          font-family: $icon-font-2;
          color: #888888;
        }
        input {
          width:100%;
          padding: 15px 20px 15px 35px;
          font-size: 14px;
        }
      }
      .call-to-action-form-button {
        display: inline-block;
      }
    }

    @include respond-to("md-tablet") {
      background-position: right center !important;
    }
    @include respond-to("sm-tablet") {
      background-position: center center !important;
      .call-to-action-form-input {
        padding-bottom: 20px;
      }
    }
  }
}

.call-to-action.style-2 {
  padding: 40px 0;
  background: #0177d7;
  .call-to-action-block {
    text-align: center;
  }
  .call-to-action-content {
    font-size: 24px;
    line-height: 43px;
    color: $white;
    font-weight: $normal;
    display: inline-block;
    margin-right: 40px;
  }

  @include respond-to("md-phone") {
    .call-to-action-block {
      text-align: left;
    }
  }
}

.call-to-action.style-3 {
  position: relative;
  .overlay {
    z-index: 0;
    width: 100%;
    height: 100%;
    background: rgba(1, 119, 215, 0.9);
    position: absolute;
    top: 0;
    left: 0;
  }
  .call-to-action-block {
    position: relative;
    text-align: center;
    padding: 115px 0;
    .call-to-action-content {
      font-size: 36px;
      color: $white;
      line-height: 1.3;
      font-weight: $normal;
    }
    button {
      margin-top: 25px;
    }
  }
}

.call-to-action.style-4 {
  padding: 70px 0;
  background: #f0f5f8;
  .call-to-action-block {
    .call-to-action-content {
      h4 {
        color: #333333;
        font-size: 24px;
      }
    }
  }

  .fancy-select-wrapper {
    display: inline-block;
    font-size: 0;
    .fancy-select {
      padding-right: 20px;
      display: inline-block;
      vertical-align: bottom;
      .trigger {
        min-width: 250px;
        padding: 14px 20px;
        border: 1px solid #cccccc;
        background: $white;
        box-shadow: none;
        font-weight: $normal;
        font-size: 14px;
        text-transform: none;
        text-align: left;
        &:after {
          background: #f0f5f8;
          border-left: 1px solid #cccccc;
          width: 15px;
          height: 15px;
          content: "\f078";
          font-family: $icon-font-2;
          display: block;
          position: absolute;
          border-top:0;
          top: -20px;
          right: 0;
          padding: 35px 20px 35px 10px;
        }
      }

      ul {
        background: $white;
        min-width: 0;
        width: 100%;
        padding: 0;
        margin-top: 5px;
        border-top: 0;
        padding-bottom: 15px;
        li {
          width: 100%;
          text-align: left;
          padding-left: 20px;
          font-weight: $normal;
          color: #666666;
          &:hover {
            color: $accent-color;
          }
          &.selected {
            background: $accent-color;
            color: $white;
          }
        }
      }
    }
  }

  button {

  }

  @include respond-to("lg-tablet") {
    .call-to-action-block {
      text-align: left;
    }
    .call-to-action-block .call-to-action-content {
      padding-bottom: 15px;
    }
  }
  @include respond-to("sm-phone") {
    .fancy-select-wrapper .fancy-select {
      padding-bottom: 15px;
    }
  }
}