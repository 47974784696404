.about-layout {
  padding: 80px 0 100px 0;
  .image {
    padding-left: 20px;
  }
  .heading {
    padding-bottom: 15px;
    margin-top: -15px;
  }
  .passage {
    padding-bottom: 25px;
    p {
      font-size: 14px;
      line-height: (25/14);
    }
  }
  
  .introdude {
    .item {
      .icon {
        padding-bottom: 15px;
        margin-left: 5px;
        i {
          font-size: 24px;
          color: #cccccc;
        }
      }

      .number {
        padding-bottom: 5px;
        span {
          line-height: 1;
          font-size: 60px;
          font-weight: $light;
          color: #333333;
        }
        .sub {
          font-size: 40px;
          color: #555555;
        }
      }

      .subtext {
        padding-left: 5px;
        span {
          text-transform: uppercase;
          font-size: 12px;
        }
      }
    }
  }

  @include respond-to("md-tablet") {
    .image {
      padding-left: 0;
      img {
        width: 100%;
      }
    }

    .introdude {
      margin-bottom:30px;
    }
  }
}

.about-layout-1 {
  padding: 90px 0;
  .heading {
    font-size: 36px;
    color: $primary-color;
    margin-bottom: 20px;
    margin-top: 0;
  }

  .content {
    font-size: 14px;
    color: #666666;
    margin-bottom: 35px;
    line-height: (22/14);
  }

  .action-group {
    .sub-link {
      display: inline-block;
      margin-left: 5px;
      span {
        font-size: 14px;
        color: #666666;
      }
      a {
        font-size: 14px;
        color: #666;
        font-weight: $bold;
        text-transform: uppercase;
        text-decoration: underline !important;
        &:hover {
          color: $accent-color;
        }
      }
    }
  }

  .image {
    img {
      width: 100%;
    }
  }

  @include respond-to('md-tablet') {
    .image {
      margin-bottom: 25px;
    }
  }
  @include respond-to("sm-phone") {
    .action-group .sub-link {
      margin-top: 10px;
    }
  }
}