/**
Get a quote
 */
.quote-form-style-1 {
  @include clearfix;
  padding-right:50px;
  .heading {
    float: left;
    padding-bottom: 25px;
    h3 {
      font-size: 24px;
      color: #333333;
    }
  }
  .fancy-select-wrapper {
    display: inline-block;
    font-size: 0;
    vertical-align: bottom;
    .fancy-select {
      display: inline-block;
      width: 100%;
      .trigger {
        min-width: 0;
        width: 100%;
        padding: 12px 20px;
        border: 1px solid #cccccc;
        background: $white;
        box-shadow: none;
        font-weight: $normal;
        font-size: 14px;
        text-transform: none;
        text-align: left;
        &:after {
          background: #f0f5f8;
          border-left: 1px solid #cccccc;
          width: 15px;
          height: 15px;
          content: "\f078";
          font-family: $icon-font-2;
          display: block;
          position: absolute;
          border-top:0;
          top: -24px;
          right: 0;
          padding: 35px 20px 35px 10px;
        }
      }

      ul {
        background: $white;
        width:100%;
        min-width: 0;
        padding: 0;
        margin-top: 5px;
        border-top: 0;
        li {
          width: 100%;
          text-align: left;
          padding-left: 20px;
          font-weight: $normal;
          color: #666666;
          &:hover {
            color: $accent-color;
          }
          &.selected {
            background: $accent-color;
            color: $white;
          }
        }
      }
    }
  }
  .form-input {
    margin-bottom: 25px;
    input {
      border: 1px solid #cccccc;
      border-radius: 3px;
      padding: 13px 17px;
      font-size: 14px;
      color: #666666 !important;
    }
    input[placeholder] {
      color: #888888;
    }
  }
  .label {
    padding: 0;
    text-align: left;
    span {
      padding-bottom: 15px;
      display: block;
      color: #555555;
      font-size: 14px;
    }
  }

  .form-group {
    float: left;
    @include clearfix;
    width: 100%;
    .form-item {
      float: left;
      width: 50%;
      padding-right: 20px;
      .form-input {
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
  }

  .form-item-group {
    float: left;
    width: 50%;
    .form-item-group-item {
      float: left;
      width: 50%;
      padding-right: 20px;
      .form-input {
        width: 100%;
        input {
          width: 100%;
        }
      }
    }
  }

  .form-button {
    float: left;
  }

  @include respond-to("md-phone") {
    .form-group .form-item {
      width: 100%;
      padding-right: 0;
    }
    .form-item-group {
      float: left;
      width: 100%;
    }
  }
  @include respond-to("sm-phone") {
    padding-right: 0;
  }
}

.quote-form-style-2 {
  .form-group .form-item {
    width: 33.33%;
  }
  .form-input input {
    border: none;
  }
  .heading h3 {
    color: $white;
  }
  .label span {
    color: $white;
  }
  .form-item-group {
    width: 33.33%;
  }

  @include respond-to("md-phone") {
    .form-group .form-item {
      width: 100%;
      padding-right: 0;
    }
    .form-item-group {
      float: left;
      width: 100%;
    }
  }
  @include respond-to("sm-phone") {
    padding-right: 0;
  }
  @include respond-to("md-tablet") {
    padding-right: 0;
  }
}

.quote-form-style-3 {
  background: #0177d7;
  .head-section {
    background: #014d8c;
    padding: 7px 20px;
    h4 {
      font-weight: $bold;
      color: #f39c12;
    }
  }
  .quote-form-container {
    padding: 44px 60px 45px 60px;
    .quote-form-heading {
      padding-bottom: 20px;
      h4 {
        color: $white;
        font-size: 24px;
      }
    }
  }
  .quote-form-item {
    margin-bottom: 12px;
    .quote-form-label {
      margin-bottom: 5px;
      span {
        color: $white;
        font-size: 14px;
        font-weight: $bold;
      }
    }
    .quote-form-input {
      input {
        font-size: 14px;
        width: 100%;
      }
    }
  }
  .quote-form-button {
    padding-top: 25px;
  }
}