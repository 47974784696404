/**
  ALL Testinmonials Layout
 */

.testinmonials {
  .testinmonial-control {
    list-style: none;
    padding: 0;
    text-align: center;
    li {
      &.active {
        background: $primary-color;
      }
      cursor: pointer;
      margin: 0 7px;
      border-radius: 50px;
      display: inline-block;
      width:10px;
      height: 10px;
      background-color: #80bbeb;
    }
  }
}

.testinmonial-item {


  &.style-1 {
    padding: 25px;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    .content {
      font-size: 14px;
      color: #888888;
      line-height: (22/14);
      padding-bottom: 10px;
    }

    .personal {
      @include clearfix;
      .avatar {
        float: left;
        margin-right: 15px;
        width: 57px;
        height: 57px;
        img {
          width: 100%;
          border-radius: 50px;
        }
      }

      .info {
        float: left;
        padding: 6px 0;
        .name {
          span {
            font-size: 13px;
            font-weight: $bold;
            color: #333333;
          }
        }
        .title-job {
          font-size: 13px;
          color: #888888;
        }
      }
    }

    @include respond-to("md-tablet") {
      margin-bottom: 30px;
    }
  }


  &.style-2 {
    width: 100%;
    .content {
      width: 850px;
      margin: 0 auto;
      padding-bottom: 15px;
      p {
        text-align: center;
        font-size: 18px;
        line-height: (30/18);
        &:before {
          @include clearfix;
          line-height: 1;
          font-family: "Arial", sans-serif;
          content: '“';
          font-weight: $bold;
          font-size: 60px;
          color: #cccccc;
        }
      }
    }
    .personal {
      width: 200px;
      text-align: center;
      margin: 0 auto;
      padding-bottom: 40px;
      @include clearfix;
      .avatar {
        width: 80px;
        height: 80px;
        float: left;
        img {
          width: 100%;
          border-radius: 50px;
        }
      }

      .info {
        padding: 17px 0;
        float: left;
        margin-left: 15px;
        text-align: left;
        .name {
          span {
            font-size: 13px;
            font-weight: $bold;
            color: #333333;
          }
        }
        .title-job {
          font-size: 13px;
          color: #888888;
        }
      }
    }

    @include respond-to("md-tablet") {
      .content {
        width: 100%;
      }
    }
  }


  &.style-3 {
    border: none;
    padding: 0;
    border-radius: 0;
    .content {
      margin: 0;
      padding-bottom: 10px;
      width: auto;
      &:before {
        float: left;
        line-height: 1;
        font-family: "Arial", sans-serif;
        content: '“';
        font-weight: $bold;
        font-size: 60px;
        color: #cccccc;
      }
      p {
        text-align: left;
        margin-left: 35px;
        padding-top: 15px;
        margin-bottom: 0;
        &:before {
          display: none;
        }
      }
    }
    .personal {
      margin: 0 0 0 35px;
      padding-bottom: 20px;
      .info {
        margin: 0;
        padding: 0;
      }
    }
  }


}

.testinmonials-layout {


  .heading {
    padding-bottom: 25px;
  }


  &.style-2 {
    padding-bottom: 70px;
    .heading {
      padding-bottom: 0;
      text-align: center;
    }
  }

  &.style-3 {
    .heading {
      padding-bottom: 10px;
      h3 {
        font-size: 20px;
        font-weight: $bold;
        color: #333333;
      }
    }
    .testinmonial-control {
      text-align: left;
      margin-left: 29px;
    }
  }

  &.style-4 {
    background-size: cover !important;
    padding-top: 50px;
    .overlay {
      background: rgba(1, 119, 215, 0.9);
    }
    .heading {
      position: relative;
      h3 {
        color: $white;
      }
    }

    .testinmonial-item.style-2 {
      .content {
        p {
          &:before {
            color: #e5e5e5;
          }
          font-weight: $normal;
          color: #e5e5e5;
        }
      }
      .personal {
        .info {
          .name {
            span {
              color: #f0f5f8;
            }
          }
          .title-job {
            span {
              color: #cccccc;
            }
          }
        }
      }
    }
  }


}
