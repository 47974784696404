/**
Agent Layout
 */

.team-item {


  &.style-1 {
    @include clearfix;
    background-color: $white;
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 40px;
    .team-image {
      float: left;
      width: 260px;
      img {
        font-size: 0;
      }
    }

    .team-info {
      margin-left: 260px;
      padding-left: 40px;
      padding-right: 40px;
      .name {
        padding-top: 30px;
        a {
          &:hover {
            color: $accent-color;
          }
          font-size: 18px;
          font-weight: $bold;
          color: #0177d7;
        }
      }
      .title-job {
        padding-bottom:15px;
        span {
          font-size: 13px;
          font-style: italic;
          color: #999999;
        }
      }
      .content {
        padding-bottom: 10px;
        p {
          font-size: 14px;
          line-height: (22/14);
        }
      }
      .phone {
        padding-bottom:10px;

        i {
          font-size: 30px;
          color: #f39c12;
          vertical-align: middle;
        }
        span {
          font-size: 16px;
          color: #0177d7;
        }
      }
      .social-list {
        list-style: none;
        padding-left: 0;
        margin: 0;
        li {
          display: inline-block;
          padding-right: 15px;
          a {
            font-size: 14px;
            color: #666666;
            &:hover {
              color: $accent-color;
            }
          }
        }
      }
    }

    @include respond-to("md-phone") {
      .team-image {
        float: left;
        width: 100%;
        img {
          width: 100%;
        }
      }

      .team-info {
        float: left;
        margin-left: 0;
        padding-bottom: 30px;
      }
    }
  }


  &.style-2 {
    margin-bottom: 50px;
    .agent-img {
      margin-bottom: 26px;
      img {
        width: 100%;
        border-radius: 3px;
      }
    }

    .agent-body {

    }
    .agent-name {
      margin-bottom: 20px;
      font-weight: $bold;
      color: #0177d7;
      margin-top: 5px;
      &:hover {
        color: $accent-color;
        transition: $fast;
      }
    }

    .agent-address, .agent-phone {
      margin-bottom: 5px;
      .fa {
        color: $accent-color;
        font-size: 16px;
        display: block;
        float: left;
        line-height: 22px;
      }
      .agent-address-home, .agent-phone-number {
        font-size: 14px;
        display: block;
        margin-left: 20px;
        line-height: 22px;
      }
    }

    .agent-btn {
      font-size: 12px;
      font-weight: $bold;
      text-transform: uppercase;
      color: #888888;
      padding: 10px 20px;
      display: inline-block;
      background-color: #f1f1f1;
      box-shadow: 0 1px 1px 0 rgba(217, 217, 217, 1);
      border-radius: 3px;
      margin-top:15px;
      &:hover {
        transition: $fast;
        background-color: $accent-color;
        color: $white;
      }

      .fa {
        margin-left: 5px;
      }
    }
  }


}

.team-layout {


  &.style-1 {
    background-color: #f0f5f8;
    padding: 70px 0;
    .heading {
      padding-bottom: 5px;
      text-align: center;
    }
    .sub-heading {
      text-align: center;
      padding-bottom: 35px;
      span {
        color: #888888;
        font-size: 18px;
        line-height: (24/18);
      }
    }
  }


  &.style-2 {
    .heading {
      padding-bottom: 25px;
      text-align: left;
    }
  }

  &.style-3 {
    background: $white;
  }


}