#loading {
  position: fixed;
  top: 0;
  min-height: 480px;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: #ffffff;
  .image-load {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -32px;
    margin-top: -32px;
    width: 64px;
    height: 64px;
    img {
      width: 100%;
    }
  }
}