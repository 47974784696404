/**
Button
 */
.au-btn {
  border: none;
  border-radius: 3px;
  padding: 15px 30px;
  font-size: 14px;
  font-weight: $bold;
  text-transform: uppercase;
  &-primary {

  }

  &-border {

  }

  &-sm {
    padding: 10px 25px;
  }

  &-md {
    padding: 15px 35px;
  }

  &-lg {
    padding: 15px 40px;
  }

  &-orange {
    background-color: #f39c12;
    color: $white;
    &:hover {
      background: darken($accent-color, 10%);
      color: $white;
    }
  }

  &-white {
    background-color: $white;
    color: #3498db;
    &:hover {
      background: darken($primary-color, 10%);
      color: $white;
    }
  }
  
  &-blue {
    background: #3498db;
    color: $white;
    &:hover {
      background: darken($primary-color, 10%) !important;
      color: $white;
    }
  }
}

.btn-social {
  line-height: 40px;
  width: 40px;
  text-align: center;
  color: #fff;
  font-size: 15px;
  border-radius: 3px;
  margin-left: 5px;
  &:hover {
    color: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  }
}

.btn-social.facebook {
  background: $facebook;
}

.btn-social.twitter {
  background: $twitter;
}

.btn-social.linkedin {
  background: $linkedin;
}