/**
HEADER
 */

#mmenu {
  z-index: 3;
}
#mmenu:not( .mm-menu ) {
  display: none;
}

.navbar-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  transition: $fast;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  &.navbar-main {
    background-color: rgba(1, 119, 215, 0.9);
  }
}

.js-global-header-scrolling {
  top: -62px; /* Height of the header */
}

.top-bar {
  /*Topbar*/
  font-size: 13px;
  color: #808080;
  padding: 10px 0;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ededed;
  @include clearfix;
  .greeting {
    float: left;
    margin-bottom: 0;
  }
  .quick-link {
    float: right;
    a {
      color: #808080;
      padding-right: 3px;
    }
  }

  @include respond-to('sm-tablet') {
    .greeting {
      float: none;
      display: block;
      text-align: center;
    }
    .quick-link {
      float: none;
      text-align: center;
    }
  }
}

.header-main {
  /*Mid Header*/
  padding: 30px 0;
  @include clearfix;

  .logo {
    float: left;
  }

  .contact-widget {
    float: right;
    .contact-list {
      @include clearfix;
      float: left;
      .item {
        float: left;
        margin-right: 48px;
        i {
          float: left;
          font-size: 24px;
          color: #002839;
          padding-top: 7px;
        }
        .text {
          margin-left: 37px;
          padding-left: 10px;
          border-left: 1px solid #e6e6e6;
          p {
            margin: 0;
            font-size: 14px;
          }
          .bot {
            color: #888888;
          }
        }
      }
    }

    .phone {
      /*Style 2*/
      float: left;
      i {
        float: left;
        font-size: 24px;
        color: #002839;
        padding-top: 7px;
      }
      .text {
        margin-left: 35px;
        padding: 6px 0 6px 10px;
        border-left: 1px solid #e6e6e6;
        p {
          margin: 0;
          font-size: 14px;
          color: #555555;
          .bold {
            font-weight: $bold;
            font-size: 18px;
            color: #555555;
            margin-left: 10px;
          }
        }
      }
    }
  }

  @include respond-to("md-phone") {
    .contact-widget {
      padding-top: 15px;
      float: left;
      i {
        display: none;
      }
      .phone .text {
        padding-left: 0;
        margin-left: 0;
        border: 0;
      }
    }
  }
}

.navbar-main {
  /*Navigation*/
  background: $primary-color;
  .navbar-holder {
    @include clearfix;
  }
  .navbar-menu {
    float: left;
  }
  .dropdown-menu, .dropdown-menu-child {
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  }
  .menu {
    padding: 0;
    margin: 0 -20px;
    li {
      display: inline-block;;
      a {
        display: block;
        font-size: 14px;
        font-weight: $bold;
        color: #f5f5f5;
        text-transform: uppercase;
        padding: 20px;
      }
    }

    .dropdown {
      &:hover {
        .dropdown-menu {
          opacity: 1;
          transition: $fast;
          top: 100%;
          visibility: visible;
        }
      }
      .dropdown-menu {
        display: block;
        border: none;
        border-radius: 0;
        margin: 0;
        padding: 0;
        background: $white;
        left: 20px;
        opacity: 0;
        transition: $fast;
        top: 115%;
        visibility: hidden;
        li {
          min-width: 260px;
          a {
            width: 100%;
            font-weight: $normal;
            font-size: 12px;
            color: #666666;
            padding: 15px;
            &:hover {
              color: $white;
              background: $primary-color;
            }
          }
        }
      }
    }

    .dropdown-child {
      position: relative;
      &:hover {
        .dropdown-menu-child {
          opacity: 1;
          transition: $fast;
          left: 100%;
          visibility: visible;
          li {
            display: block;
          }
        }
      }
      .dropdown-menu-child {
        position: absolute;
        display: block;
        box-shadow: none;
        border: none;
        border-radius: 0;
        margin: 0;
        padding: 0;
        background: $white;
        left: 115%;
        opacity: 0;
        transition: $fast;
        top: 0;
        visibility: hidden;
        li {
          display: none;
          min-width: 260px;
          a {
            width: 100%;
            font-weight: $normal;
            font-size: 12px;
            color: #666666;
            padding: 15px;

            &:hover {
              color: $white;
              background: $primary-color;
            }
          }
        }
      }
    }
  }

  .search {
    /*Search*/
    float: right;
    margin-top: 17px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    .search-input {
      border: none;
      font-size: 14px;
      transition: $fast;
      width: 0;
      padding: 3px 28px 3px 0;
      visibility: hidden;
    }
    .search-submit {
      position: absolute;
      top:0;
      right:0;
      background: transparent;
      border: none;
      width: 28px;
      height: 28px;
      z-index: 90;
      cursor: pointer;
      opacity:0;
    }
    .search-icon {
      border-radius: 3px;
      background: $accent-color;
      position: absolute;
      top:0;
      right: 0;
      color: $white;
      width: 28px;
      height: 28px;
      display: table;
      text-align: center;
      z-index: 11;

      &:before {
        vertical-align: middle;
        display: table-cell;
        font-family: $icon-font-2;
        content: '\f002';
        color: $white;
      }
    }

    &-open {
      .search-input {
        visibility: visible;
        width: 250px;
        padding: 3px 28px 3px 5px;
      }
      .search-icon {
        border-radius: 0 3px 3px 0;
        //background: darken($accent-color, 10%);
      }
    }

    &:hover {
      .search-icon {
        transition: $fast;
        background: darken($accent-color, 10%);
      }
    }
  }

  @include respond-to('md-to-lg-tablet') {
    .search {
      .search-input {
        width: 150px;
      }
    }
  }

  @include respond-to('md-tablet') {
    .navbar-toggle {
      display: block !important;
      float: left;
      padding: 24px 0;
      margin: 0;
      border: 0;
      .icon-bar {
        background: $white;
      }
    }
    .navbar-menu {
      display: none;
    }

    .search {

    }
  }

}