/**
Form Input
 */
.au-form {

  &-control {
    border: none;
    padding: 13px;
  }
  &-radius {
    border-radius: 3px;
  }

  &-border {
    border: 1px solid #cccccc;
  }

  &-group {

  }

  &-inline {

  }

  &-horizontal {
    
  }
}

