/**
SERVICES PAGE
 */
.services-detail-1 {
  padding: 75px 0 100px 0;
  .sidebar-container {
    margin-bottom: 35px;
  }
  .p1 {
    padding-top:5px;
    padding-bottom: 15px;
  }
  .p2 {
    padding-bottom:15px;
  }

  .post-table {
    padding-bottom: 10px;
  }
  .post-with-image {
    padding-bottom: 30px;
    .post-paragraph {
      padding-bottom: 15px;
    }
  }
  .p3 {
    padding-bottom:15px;
  }

  .post-content-box {
    margin-bottom:50px;
  }
  .testinmonials {
    padding-bottom: 45px;
  }

  @include respond-to("md-to-lg-tablet") {
    .sidebar-style-1 .sidebar-container .sidebar-item a i {
      margin-right: 5px;
    }
  }

  @include respond-to("lg-tablet") {
    .slider-medium-layout.style-1 .slider-item .slider-dots {
      display: none;
    }
    .slider-medium-layout.style-1 .slider-item .slider-title {
      padding-right: 15px;
    }
    .post-form-action .form-block {
      float: left;
    }
  }

  @include respond-to("md-tablet") {
    .post-services {
      margin-bottom: 50px;
    }
    .slider-medium-layout.style-1 .slider-item .slider-btn {
      button {
        margin-left: 25px;
      }
    }
    .slider-medium-layout.style-1 .slider-item .slider-title {
      padding-right: 0;
    }
  }

  @include respond-to("md-phone") {
    .post-table table tbody tr .tblock span {
      float: none;
    }
    .post-table table tbody tr .tblock .post-btn button {
      margin-bottom: 15px;
    }
    .post-form-action .form-block input {
      width: 100%;
      min-width: 0;
      margin-bottom: 20px;
    }
    .slider-medium-layout.style-1 .slider-item .slider-text-holder {
      display: none;
    }
  }
}

//////////////////////////////////

.services-detail-2 {
  padding-bottom: 135px;
  padding-top: 20px;
  .heading-page {
    padding-bottom: 40px;
    h3 {
      font-weight: $bold;
    }
  }
  .p1 {
    padding-bottom: 15px;
  }
  .p2 {
    padding-bottom: 15px;
  }
  .post-table {
    padding-bottom:15px;
  }
  .post-with-image {
    padding: 30px 0;
    border-bottom: 1px solid #e6e6e6;
    .custom-col-5 {
      float: left;
      width: ((335/770)*100%);
    }
    .custom-col-7 {
      float: left;
      width: (((770-335)/770)*100%);
    }

    .custom-push-7 {
      left:(((770-335)/770)*100%) ;
    }

    .custom-pull-5 {
      right: ((335/770)*100%);
    }
    .post-paragraph {
      .post-heading {
        h3 {
          margin-top: 5px;
        }
      }
    }
  }
  .testinmonials {
    padding-bottom: 30px;
  }

  .sidebar-container {
    margin-bottom: 35px;
  }
  .banner {
    margin-bottom: 40px;
  }

  @include respond-to("lg-tablet") {
    .post-form-action .form-block {
      float: left;
    }
  }

  @include respond-to("md-tablet") {
    .post-with-image {
      .custom-push-7, .custom-pull-5 {
        left:auto ;
        right:auto;
      }
    }
  }

  @include respond-to("md-phone") {

    .post-with-image {
      .post-image {
        margin-bottom: 15px;
      }
      .custom-col-5, .custom-col-7 {
        width: 100%;
      }
    }
  }
}

//////////////////////////////////

.services-detail-2,
.services-detail-3 {

  .post-form-action .form-block button {
    padding: 15px 55px;
  }

  .post-form-action .form-block input {
    min-width: 250px;
  }

  @include respond-to("md-phone") {
    .slider-medium-layout.style-1 .slider-item .slider-text-holder {
      display: none;
    }
    .slider-medium-layout.style-1 .slider-item .slider-dots {
      display: none;
    }
    .post-form-action .form-block input {
      width: 100%;
      min-width: 0;
      margin-bottom: 20px;
    }
  }
  @include respond-to("lg-tablet") {
    .post-form-action .form-block {
      float: left;
    }
    .post-services {
      margin-bottom: 50px;
    }
  }
}

//////////////////////////////////

.services-detail-3 {
  padding-bottom: 100px;
  padding-top: 60px;

  .sidebar-container {
    margin-bottom: 40px;
  }
  .banner {
    margin-bottom: 40px;
  }

  .heading-page {
    .au-breadcrumb {
      padding-top: 0;
    }
  }
  .title-page {
    padding-top: 25px;
    padding-bottom: 20px;
  }
  .slider-medium-layout {
    padding-bottom: 5px;
  }

  .p1, .p2 {
    padding-bottom: 15px;
  }
  .post-with-image {
    padding-bottom: 15px;
  }

  .post-accordion {
    padding-bottom: 25px;
  }
  .testinmonials {
    padding-bottom: 35px;
  }

  @include respond-to("md-tablet") {
    .post-with-image {
      .post-image {
        img {
          width: 100%;
        }
      }
    }
  }
}

//////////////////////////////////

.services-detail-4 {
  .post-services-detail-4 {
    .p2 {
      padding-bottom: 20px;
    }
    .p3 {
      .post-heading {
        h3 {
          margin-top: 0;
        }
      }
    }
    .post-icon-box {
      padding-bottom: 25px;
    }
  }

  .down-file-widget {
    margin-bottom: 30px;
  }

  @include respond-to("md-tablet") {
    .post-icon-box-item {
      margin-bottom: 30px;
    }
  }
  @include respond-to("md-phone") {
    .post-with-image-style-3 .post-image {
      width: 100%;
      float: left;
      margin-bottom: 15px;
    }
    .post-with-image .post-paragraph {
      margin-left: 0;
      padding-left: 0;

    }
  }
}