/**
Helper Class
 */
.show {
  visibility: visible !important;
}

.hidden {
  visibility: hidden !important;
}
.w-50 {
  width: 50%;
}

.w-sm-100 {
  @include respond-to("md-tablet") {
    width: 100%;
  }
}

@include respond-to('desktop-up') {
  .hidden-desktop-up {
    display: none;
  }
}

@include respond-to('sm-tablet') {
  .hidden-sm-tablet {
    display: none;
  }
}

@include respond-to('md-tablet') {
  .hidden-sm-tablet {
    display: none;
  }
}


@include respond-to('lg-tablet') {
  .hidden-lg-tablet {
    display: none;
  }
}

@include respond-to('md-phone') {
  .hidden-md-phone {
    display: none;
  }
}

@include respond-to('sm-phone') {
  .hidden-sm-phone {
    display: none;
  }
}
