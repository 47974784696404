/**
Heading page layout
 */
.heading-page {
  background-size: cover !important;
}

.heading-normal {
  background: $white;
  border-bottom: 1px solid #ebebeb;
  .au-breadcrumb {
    padding: 15px 0;
    .au-breadcrumb-item {
      a {
        color: #999999;
        &:hover {
          color: $primary-color;
        }
      }
      &:after {
        color: $primary-color;
      }
    }
  }
}

.heading-services-list {
  padding: 70px 0 60px 0;
  @include clearfix;
  .heading-title {
    float: left;
    h1 {
      margin: 0;
      font-size: 36px;
      color: #333333;
      font-weight: $bold;
    }
  }
  .au-breadcrumb {
    float: right;
    .au-breadcrumb-item {
      line-height: 39px;
      a {
      }
    }
  }

  &.invert {
    .heading-title {
      h1 {
        color: $white;
      }
    }
    .au-breadcrumb {
      .au-breadcrumb-item {
        a, &:after {
          color: $primary-color;
        }
      }
    }
  }

  @include respond-to("md-phone") {
    .heading-title {
      float: none;
    }
    .au-breadcrumb {
      float: none;
    }
  }
}

.heading-services-detail-1 {
  padding: 45px 0;
  .au-breadcrumb {
    .au-breadcrumb-item {
      line-height: 39px;
      &:after {
        color: $primary-color;
      }
      a {
        color: $primary-color;
      }
    }
  }
  .heading-title {
    h1 {
      margin: 0;
      font-size: 36px;
      color: $white;
      font-weight: $bold;
    }
  }
}

.heading-services-detail-5 {
  text-align: center;
  padding: 110px 0 125px 0;
  .heading-title {
    h1 {
      color: $white;
      font-weight: $bold;
      margin: 0;
    }
  }
  .au-breadcrumb {
    text-align: center;
    display: inline-block;
    .au-breadcrumb-item {
      a, &:after {
        color: #cccccc;
      }
    }
  }
}

.heading-agent-list-1 {
  .heading-title {
    h1 {
      font-size: 48px;
    }
  }
}

.heading-agent-detail {
  position: relative;
  .content-holder {
    padding: 70px 0 0 0;
    margin-bottom: 30px;
  }
  .agent-main {
    padding-top: 70px;
    padding-bottom: 50px;
    padding-left: 15px;
    .heading-title {
      padding-bottom: 20px;
      h1 {
        margin-top: 0;
        color: $white;
        font-weight: $bold;
      }
    }
    .au-breadcrumb {
      padding-bottom: 15px;
      .au-breadcrumb-item {
        color: #cccccc;
        a {
          color: #cccccc;
        }
      }
    }
  }
  .agent-image {
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    top: 30px;
  }
  button {
    display: inline-block;
    float: left;
    margin-right: 5px;
    padding: 9px 15px;
  }
  .contact-group {
    @include clearfix;
  }
  .social-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: inline-block;
    .facebook {
      background: $facebook;
    }
    .twitter {
      background: $twitter;
    }
    .linkedin {
      background: $linkedin;
    }
    li {
      margin-right: 5px;
      display: table;
      float: left;
      width: 40px;
      height: 40px;
      text-align: center;
      border-radius: 3px;
      a {
        display: table-cell;
        vertical-align: middle;
      }
      i {
        font-size: 15px;
        color: $white;
      }
    }
  }
  @include respond-to("lg-tablet") {
    .content-holder {
      margin-bottom: 0;
    }
  }
  @include respond-to("md-tablet") {
    .content-holder {
      padding-top: 30px;
    }
    .agent-image {
      top: 70px;
    }
  }
  @include respond-to("sm-tablet") {
    .agent-image {
      margin-bottom: 30px;
      img {
        width: 100%;
      }
    }
  }
}

.heading-get-a-quote {
  padding: 40px 0;
  .heading {
    h1 {
      font-weight: $bold;
      color: $white;
    }
  }
  .sub-heading {
    span {
      font-size: 14px;
      color: #e5e5e5;
    }
  }
}

.heading-get-a-quote-1 {
  padding: 100px 0;
  text-align: center;
  .heading {
    padding-bottom: 15px;
    h1 {
      font-weight: $bold;
      color: $white;
      font-size: 48px;
    }
  }
  .sub-heading {
    span {
      font-size: 18px;
      color: #cccccc;
      font-style: italic;
    }
  }
}

.heading-get-a-quote-2 {
  position: relative;
  .heading-content {
    position: absolute;
    top: 15px;
    left: 45px;
  }
  .heading {
    h1 {
      font-size: 36px;
      font-weight:$bold;
      color: $white;
      margin-bottom: 0;
    }
  }
  .sub-heading {
    span {
      font-size: 14px;
      color: #e5e5e5;
    }
  }
}

.heading-project {
  background: #f2f2f2;
  .au-breadcrumb {
    .au-breadcrumb-item {
      &.active a, &:after {
        color: #999999;
        content: '/';
      }
      a, i {
        font-size: 13px;
        color: #555555;
      }
    }
  }
}