/**
Background
 */

.bg-parallax-style-1 {
  background-size: cover;
  position: relative;
  .overlay {
    z-index: 0;
    width: 100%;
    height: 100%;
    background: rgba(1, 119, 215, 0.8);
    position: absolute;
    top: 0;
    left: 0;
  }
}