/**
  Post services
 */

.post-services {

}


.post-paragraph {
  .post-heading {
    h3 {
      font-size: 20px;
      font-weight: $bold;
      color: #333333;
    }
  }
  .post-content {
    p {
      font-size: 14px;
      line-height: (22/14);
    }
  }
}

.post-table {
  table {
    border-collapse:collapse;
    tbody {
      tr {
        td {
          padding: 13px 20px;
          font-size: 14px;
        }
        .tblock {
          background-color: #f2f2f2;
          padding: 30px 20px;
          text-align: center;
          .tblock-container {
            display: inline-block;
            @include clearfix;
          }
          span {
            font-size: 14px;
            float: left;
            line-height: 3;
            margin-right: 35px;
          }
          .post-btn {
            float: left;

            button {
              background-color: #0177d7;
              font-size: 14px;
              font-weight: $normal;
              text-transform: none;
              margin-left: 5px;
              i {
                margin-right: 10px;
              }
              &:hover {
                background-color: #0269bd;
              }
            }
          }
        }
      }
    }
  }
}

.post-table-style-1 {
  table {
    border: 0;
  }

  table tbody tr td {
    padding: 13px 20px;
  }
  .title-col {
    font-weight: $bold;
  }
  .title-row {

    td {
      border-top: 3px solid #f39c12;
      font-size: 15px;
      color: $primary-color;
      font-weight: $bold;
    }
    td:first-child {
      border: 0;
    }
  }
}

.post-content-box {
  padding: 30px 30px 10px 30px;
  border: 1px solid #d9d9d9;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 20px;
      font-size: 14px;
      @include clearfix;
      p {
        margin-bottom: 0;
        margin-left: 35px;
      }
      i {
        float: left;
        color: #0177d7;
        font-size: 18px;
      }
      .bold {
        margin-right: 7px;
        font-weight: $bold;
      }
    }
  }
}

.post-form-action {
  padding: 20px 40px;
  background-color: #f2f2f2;
  border-top: 3px solid #d9d9d9;
  @include clearfix;
  .content {
    float: left;
    padding-right: 20px;
    .heading {
      h3 {
        font-size: 20px;
        font-weight: $bold;
        color: #333333;
      }
    }
    .sub-heading {
      p {
        font-size: 14px;
        line-height: (22/14);
      }
    }
  }
  .form-block {
    padding-top:20px;
    input {
      min-width: 300px;
      border: 1px solid #cccccc !important;
      margin-right: 10px;
      font-size: 14px;
      color: #888888;
      padding: 15px;
    }
    button {
      padding: 15px 70px;
    }
  }
}

.post-accordion {
  .accordion {
    border-bottom: 1px solid #e5e5e5;
    padding: 15px 0;
  }
  .accordion-title {
    padding: 0;
    border-bottom: 0;
    cursor: pointer;
    &:after {
      display: none;
    }
    i {
      float: left;
      line-height: 1;
      margin-right: 7px;
      font-size: 20px;
      color: #b2b2b2;
    }
    h4 {
      color: #333333;
      font-size: 15px;
      margin: 0;
    }
  }

  .accordion-content {
    p {
      font-size: 14px;
      line-height: (22/14);
      border-bottom: 0;
      margin: 0;
      padding-left: 0;
    }
  }
}

.post-with-image-style-2 {
  .post-paragraph {
    h3 {
      font-size: 14px;
      line-height: (18/14);
    }
  }
}

.post-with-image-style-3 {
  padding-bottom: 40px !important;
  .post-paragraph {
    a {
      font-weight: $bold;
      color: #333;
      margin-top: 0;
      font-size: 14px;
      line-height: (18/14);
      &:hover {
        color: $accent-color;
      }
    }
  }
  .post-image {
    width: 120px;
    float: left;
  }
  .post-paragraph {
    padding-bottom:  0 !important;
    p {
      margin: 0;
    }
    padding-left: 20px;
    margin-left: 120px;
  }
}

.post-icon-box-item {
  text-align: center;
  .post-icon-box-icon {
    margin: 0 auto;
    width: 80px;
    height: 80px;
    background: #666666;
    border-radius: 50px;
    display: table;
    text-align: center;
    i {
      display: table-cell;
      vertical-align: middle;
      color: $white;
      font-size: 30px;
    }
  }
  .post-icon-box-title {
    padding-top:5px;
    h4 {
      font-weight: $bold;
      font-size: 14px;
      color: #333333;
      line-height: (18/14);
    }
  }
}

.accordion-layout {
  padding: 70px 0 100px 0;
  .heading-layout {
    padding-bottom: 40px;
    text-align: center;
  }
}