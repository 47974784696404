/**
Partner
 */

.partner-layout {


  &.style-1 {
    padding: 80px 0 100px 0;
    .partner-list {
      margin: 0 -15px;
      margin: 0;
      padding: 0;
      @include clearfix;
      .partner-item {
        font-size: 0;
        padding: 0 15px;
        display: inline-block;
        width: 100%;
        a {
          text-align: center;
          border: 1px solid #e5e5e5;
          display: block;
          width: 205px;
          margin: 0 auto;
          img {
            padding: 15px 0;
            width: 120px;
            height: 105px;
            margin: 0 auto;
          }
        }
      }
    }
  }


}