/**
Slider Layout
 */

.slider-medium {

}

.slider-primary {
  .slider-title {
    font-weight: $bold;
    font-size: 60px;
    color: $white;
  }
  .slider-subtitle {
    font-size: 24px;
    line-height: 36px;
    font-style: italic;
    color: $white;
  }

  .slider-item-2 {
    text-align: center;
    .slider-title {
      line-height: 60px;
    }
    .slider-subtitle {
      font-size: 18px;
      font-style: normal;
      font-weight: $normal;
      line-height: 30px;
    }
    .btn {
      padding: 16px 25px;
    }
  }

  @include respond-to("md-phone") {
    .tp-caption {
      display: none;
    }
  }
}

.slider-medium-layout {


  &.style-1 {
    .slider-item {
      position: relative;
      .slider-image {
        img {

        }
      }
      .slider-text-holder {
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
        width: 100%;
      }
      .owl-item.active {
        .slider-text-holder {
          animation-name: fadeInUp;
        }
      }

      .slider-title {
        float: left;
        padding: 17px 30px 17px 25px;
        a {
          font-size: 18px;
          font-weight: $bold;
          color: $white;
          text-transform: uppercase;
        }
      }
      .slider-btn {
        padding: 10px 0;
      }
      .slider-dots {
        z-index: 10;
        list-style: none;
        padding:0;
        margin: 0;
        @include clearfix;
        position: absolute;
        bottom: 27px;
        right: 30px;
        li {
          cursor: pointer;
          margin-left: 5px;
          width:8px;
          height:8px;
          background: #818181;
          border-radius: 5px;
          float: left;

          &.active {
            background: $primary-color;
          }
        }
      }
      .slider-arrow {

        .next {
          position: absolute;
          top: 50%;
          right: 30px;
          margin-top: -18px;
        }

        .prev {
          position: absolute;
          top: 50%;
          left: 30px;
          margin-top: -18px;
        }

        .prev, .next {
          z-index: 10;
          cursor: pointer;
          width: 36px;
          height: 36px;
          border-radius: 3px;
          background-color: rgba(0, 0, 0, 0.8);
          display: table;
          text-align: center;
          i {
            display: table-cell;
            vertical-align: middle;
            font-size: 18px;
            color: $white;
          }

          &.active, &:hover {
            transition: $fast;
            background-color: rgba(2, 96, 173, 0.8);
          }
        }
      }
    }
  }

  &.style-2 {
    .slider-item .slider-title {
      float: none;
      padding-bottom: 10px;
    }
    .slider-item .slider-btn {
      padding-left: 25px;
      padding-bottom: 20px;
      padding-top: 0;
    }
  }


}